import React, { useState } from "react";
import styles from "./index.module.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Button } from "antd";
import { useAuthAction, useAuthData } from "../../../auth/JWTAuthProvider";
import { FaArrowLeft } from "react-icons/fa";
import { menuLinks } from "../../../pages/menuLinks";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross1 } from "react-icons/rx";
import clsx from "clsx";
import { IoTrashOutline } from "react-icons/io5";
import { CourseType } from "../../../shared/types/CourseType";
import { SectionType } from "../../../shared/types/SectionType";
import CourseNavbar from "./CourseNavbar";

type PropType = {
  course?: CourseType;
  type?: "course" | "profile" | "section";
  backUrl?: string;
  sectionTitle?: string;
};

export default function Navbar({
  course,
  backUrl = "/courses",
  type = "profile",
  sectionTitle="",
}: PropType) {
  const { logout, refetchProfile } = useAuthAction();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { user } = useAuthData();

  const backLinks = {
    "/profile/change-email": "/profile/info",
    "/profile/plans": "/profile/info",
    "/profile/wallet": "/profile/info",
    "/profile/change-password": "/profile/info",
    "/profile/change-email-code": "/profile/change-email",
    "/profile/purchase": "/profile/info",
    "/profile/info": user?.activePlan
      ? user?.userData.startedCourses[0]
        ? `/platform/course/${user.userData.startedCourses[0]._id}`
        : `/courses`
      : "/courses",
  };

  return (
    <nav className={styles.navbar}>
      {type === "course" ? (
        <CourseNavbar course={course} />
      ) : type === "section" ? (
        <div className={styles.section}>
          <Link to={backUrl} className={styles.back}>
            <FaArrowLeft />
            <p>{sectionTitle}</p>
          </Link>
        </div>
      ) : (
        <div className={clsx(open || styles.closed, styles.profile)}>
          <Link
            to={backLinks[location.pathname as keyof typeof backLinks]}
            className={styles.back}
          >
            <FaArrowLeft />
            <p>Shaxsiy kabinet</p>
          </Link>

          <div
            className={clsx(styles.menuBg, styles.menuEl)}
            onClick={() => setOpen(false)}
          ></div>
          <div className={styles.links}>
            <RxCross1
              size={30}
              color="var(--white-color)"
              className={clsx(styles.closeBtn, styles.menuEl)}
              onClick={() => setOpen(false)}
            />

            {menuLinks.map((link) => (
              <NavLink to={link.path} id="link">
                {link.label}
              </NavLink>
            ))}
            <NavLink
              to={"/"}
              id="link"
              onClick={logout}
              className={styles.menuEl}
            >
              Logout
            </NavLink>
            <Button onClick={logout} ghost>
              Logout
            </Button>
          </div>
          <RxHamburgerMenu
            onClick={() => setOpen(true)}
            size={30}
            color="var(--white-color)"
            className={clsx(styles.menuBtn, styles.menuEl)}
          />
        </div>
      )}
    </nav>
  );
}
