import React, { useState } from "react";
import { CourseType } from "../../../shared/types/CourseType";
import styles from "./index.module.scss";
import { Button, notification } from "antd";
import { IoTrashOutline } from "react-icons/io5";
import jwtAxios from "../../../auth/jwtAxios";
import { useAuthAction } from "../../../auth/JWTAuthProvider";
import { useNavigate } from "react-router-dom";

export default function CourseNavbar({ course }: { course?: CourseType }) {
  const [loading, setLoading] = useState(false);
  const { refetchProfile } = useAuthAction();
  const navigate = useNavigate();

  const removeCourse = async () => {
    setLoading(true);
    try {
      await jwtAxios.delete("/user/remove-course", {
        params: { courseId: course?._id },
      });
      notification.success({
        message: "Success",
        description: "Kursdan chiqdingiz",
      });
      navigate("/courses");
      refetchProfile();
    } catch (error:any) {
      notification.error({
        message: "Xatolik",
        description: error?.response?.data?.error || "Xatolik yuz berdi",
      });
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className={styles.course}>
      <p>{course?.title || "Loading..."}</p>
      {course?._id && (
        <Button ghost onClick={removeCourse} loading={loading}>
          <span className={styles.word}>Kursdan chiqish</span>
          <IoTrashOutline className={styles.icon} />
        </Button>
      )}
    </div>
  );
}
