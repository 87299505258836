import { RouteType } from "../../shared/types/RoutType";
import EmailSent from "./EmailSent";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordCode from "./ForgotPasswordCode";
import Signin from "./Signin";
import Signup from "./Signup";

export const authRoutes: RouteType[] = [
  {
    path: "/auth/signup",
    element: <Signup />,
  },
  {
    path: "/auth/signin",
    element: <Signin />,
  },
  {
    path: "/auth/email-sent",
    element: <EmailSent />,
  },
  {
    path: "/auth/forgot-password",
    element: <ForgotPassword />,
  },{
    path: "/auth/forgot-password-code",
    element: <ForgotPasswordCode />,
  },
];
