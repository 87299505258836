import React, { useState } from "react";
import styles from "./index.module.scss";
import { CourseType } from "../../../shared/types/CourseType";
import { Button, notification } from "antd";
import jwtAxios from "../../../auth/jwtAxios";
import { useNavigate } from "react-router-dom";
import { useAuthAction } from "../../../auth/JWTAuthProvider";

type PropType = {
  course: CourseType;
};
export default function Course({ course }: PropType) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { refetchProfile } = useAuthAction();
  const handleClick = async () => {
    setLoading(true);
    try {
      await jwtAxios.post("/user/add-course", {
        courseId: course._id,
      });
      notification.success({
        message: "Success",
        description: "Kursga yozildingiz",
      });
      await refetchProfile();
      navigate(`/platform/course/${course._id}`);
    } catch (error: any) {
      notification.error({
        message: "Xatolik",
        description: error?.response?.data?.error || "Xatolik yuz berdi",
      });
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className={styles["trailer-container"]}>
      <div className={styles.trailer}>
        {course.trailer && (
          <iframe
            width="100%"
            style={{ aspectRatio: "16/9" }}
            src={course.trailer.link}
             allowFullScreen
          ></iframe>
        )}
        <p             dangerouslySetInnerHTML={{
              __html: course.description ||"",
            }}
></p>
        <Button
          block
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleClick}
        >
          Kursni boshlash
        </Button>
      </div>
    </div>
  );
}
