import { ConfigProvider, notification } from "antd";
import AppLayout from "./layouts";
import JWTAuthAuthProvider from "./auth/JWTAuthProvider";
import { QueryClientProvider, QueryClient } from "react-query";

const queryClient = new QueryClient();


notification.config({
  placement: 'bottomLeft',
});
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <JWTAuthAuthProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#EBC870",
            },
          }}
        >
          <AppLayout />
        </ConfigProvider>
      </JWTAuthAuthProvider>
    </QueryClientProvider>
  );
}

export default App;
