import { RouteType } from "../../shared/types/RoutType";
import NotFound from "./Error404";
import { Navigate } from "react-router-dom";

export const errorRoutes: RouteType[] = [
  {
    path: "/error/404",
    element: <NotFound />,
  },
  {
    path: "*",
    element: <Navigate to="/error/404/" />,
  },
];
