import axios from "axios";
import { REACT_APP_API_URL } from "../shared/const/env";

const jwtAxios = axios.create({
  baseURL: `${REACT_APP_API_URL}/api/v1`,
  headers: {
    "Content-Type": "application/json",
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data?.message === "Unauthenticated.") {
      localStorage.removeItem("token");
      window.location.replace("/");
    }

    return Promise.reject(err);
  }
);

export const setAuthToken = (token?: string) => {
  if (token) {
    jwtAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("token", token);
  } else {
    delete jwtAxios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};

export default jwtAxios;
