import React, { useState } from "react";
import styles from "./index.module.scss";
import { Button, notification } from "antd";
import { VideoType } from "../../../shared/types/VideoType";
import { useNavigate, useParams } from "react-router-dom";
import jwtAxios from "../../../auth/jwtAxios";

export default function Video({
  video,
  nextVideoId,
  refetchProgress,
  isLastVideo,
}: {
  video: VideoType;
  nextVideoId: string;
  refetchProgress: () => void;
  isLastVideo: boolean;
}) {
  const { sectionId, videoId, courseId } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  if (!video) {
    return <> </>;
  }

  console.log({isLastVideo});
  
  const nextVideo = async () => {
    setLoading(true);
    try {
      await jwtAxios.post(
        "/progress/next-video",
        {},
        {
          params: { sectionId },
        }
      );
      refetchProgress();

      navigate(
        nextVideoId
          ? `/platform/section/${sectionId}/course/${courseId}/video/${nextVideoId}`
          : `/platform/course/${courseId}`
      );
    } catch (error:any) {
      notification.error({
        message: "Xatolik",
        description: error?.response?.data?.error || "Keyingi kursga o'tib bo'lmadi!",
      });

      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className={styles["video-content"]}>
      <div className={styles.iframe}>
        <iframe
          src={video.link}
          allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
          allowFullScreen
          style={{ width: "100%", height: "100%" }}
        ></iframe>
      </div>
      <div className={styles["title"]}>
        <h1>{video.title}</h1>
        {!isLastVideo && (
          <Button loading={loading} ghost onClick={nextVideo}>
            Keyingisi
          </Button>
        )}
      </div>
      <div
        className={styles["description"]}
        dangerouslySetInnerHTML={{
          __html: video.description,
        }}
      ></div>
    </div>
  );
}
