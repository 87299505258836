import React, { CSSProperties } from "react";
import GradientBorder from "../../../components/GradientBorder";
import styles from "./index.module.scss";
import chip from "../../../assets/chip.png";
import { Button, Spin } from "antd";
 import { useAuthData } from "../../../auth/JWTAuthProvider";
import { useQuery } from "react-query";
import jwtAxios from "../../../auth/jwtAxios";
import { Link } from "react-router-dom";
import ProfileTemplate from "../../../components/ProfileTemplate";
import { colors } from "../../../shared/const/variables";

export default function Wallet() {
  const { user } = useAuthData();

  const { data, isLoading } = useQuery("purchase-history", () =>
    jwtAxios.get("/purchase/history")
  );
  const history = data?.data.data.purchases;


  
  return (
    <ProfileTemplate title="Hamyon">
      <GradientBorder className={styles["card-container"]}>
        <div className={styles.card}>
          <h2 className={styles["card-title"]}>Card</h2>
          <div className={styles["visa"]}>
            <h3 className={styles["visa-title"]}>
              Nextech platforma kartasi
            </h3>
            <p className={styles["visa-type"]}>Raqamli hamyon</p>
            <div className={styles["visa-info"]}>
              <div className={styles["visa-cost"]}>
                <h1>{user?.balance || 0}.00$</h1>
                <p>Fazliddin Mirzaqosimov</p>
              </div>
              <div className={styles["visa-logo"]}>
                <img src={chip} alt="" />
                <h1>FAZ</h1>
              </div>
            </div>
          </div>
          <Link to="/profile/purchase" className={styles.link}>
            <Button
               type="primary"
            >
              Hisobni to’ldirish
            </Button>
          </Link>
        </div>
        {isLoading ? (
          <Spin className={styles.spin}></Spin>
        ) : history?.[0] ? (
          <div className={styles.history}>
            <h2 className={styles["card-title"]}>Tarix</h2>
            <div className={styles["history-lists"]}>
              {history.map((history: any) => (
                <div
                  className={styles["history-list"]}
                  style={
                    {
                      "--color": colors[history?.planId?.order] || "rgb(255, 255, 255 , 0.6)",
                    } as CSSProperties
                  }
                >
                  <h2>{history.amount}$</h2>
                  <p>{history.createdAt.split("T")[0]}</p>
                  {history.planTitle && <h3>{history.planTitle}</h3>}
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </GradientBorder>
    </ProfileTemplate>
  );
}
