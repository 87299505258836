import React from "react";
import { Link } from "react-router-dom";

export default function Erorr404() {
  return (
    <div>
      <h1>
        <Link to="/profile/info">Erorr404</Link>
      </h1>
    </div>
  );
}
