import React from "react";
import styles from "./index.module.scss";
import { Link, NavLink } from "react-router-dom";
import { useAuthData } from "../../../auth/JWTAuthProvider";
import { GoPlus } from "react-icons/go";
import clsx from "clsx";
import profile from "../../../assets/profile.png";
import noimage from "../../../assets/noimage.png";
import { REACT_APP_API_URL } from "../../../shared/const/env";

export default function Sidebar() {
  const { user } = useAuthData();

  return (
    <aside className={styles.container}>
      <Link to="/profile/info" className={styles.menuLink}>
        <img
          src={
            user?.userData.profileImage
              ? `${REACT_APP_API_URL}${user?.userData.profileImage}`
              : profile
          }
          alt=""
          className={styles.profile}
          onError={(e) => {
            e.currentTarget.src = profile;
            e.currentTarget.onerror = null; // prevents looping
          }}
        />
      </Link>
      <div className={styles.line}></div>
      {
        // user?.activePlan &&
        user?.userData.startedCourses.map((course: any) => (
          <NavLink
            to={`platform/course/${course._id}`}
            key={course._id}
            className={({ isActive }) =>
              isActive ? clsx(styles.menuLink, styles.active) : styles.menuLink
            }
          >
            {
              <img
                src={
                  course?.image?.location
                    ? `${REACT_APP_API_URL}${course.image.location}`
                    : noimage
                }
                className={styles.courseImage}
                alt=""
                onError={(e) => {
                  e.currentTarget.src = noimage;
                  e.currentTarget.onerror = null; // prevents looping
                }}
              />
            }
          </NavLink>
        ))
      }
      <NavLink
        to="/courses"
        className={({ isActive }) =>
          isActive ? clsx(styles.menuLink, styles.active) : styles.menuLink
        }
      >
        <GoPlus />
      </NavLink>
    </aside>
  );
}
