import { MenuLinkType } from "../shared/types";

export const menuLinks: MenuLinkType[] = [
  {
    label: "Kurslar",
    path: "/courses",
    id: 1,
  },
  {
    label: "Profil",
    path: "/profile/info",
    id: 2,
  },
  // {
  //   label: "Tariflar",
  //   path: "/profile/plans",
  //   id: 3,
  // },
  // {
  //   label: "Hamyon",
  //   path: "/profile/wallet",
  //   id: 4,
  // },
];