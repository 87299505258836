import React from "react";
import { Button, Checkbox, Form, Input, notification } from "antd";
import FormContainer from "../../../components/FormContainer";
import { Link, useNavigate } from "react-router-dom";
import jwtAxios from "../../../auth/jwtAxios";
import { useMutation } from "react-query";

export default function ChangeEmail() {
  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(
    (data: { newEmail: string }) =>
      jwtAxios.post("/auth/send-new-email-code", data).then(() => {
        notification.success({
          message: "Yangi Emailingizga kod jo'natildi!",
        });
        navigate(`/profile/change-email-code?email=${data.newEmail}`);
      }),
    {
      onError(error: any) {
        notification.error({
          message: "Xatolik",
          description:
            error?.response?.data?.error ||
            "Emailingizni kiritishda xatolik yuz berdi!",
        });
      },
    }
  );

  return (
    <FormContainer title="Emailni o’zgaritrish!">
      <Form layout="vertical" onFinish={mutate}>
        <Form.Item
          label="Yangi Email"
          name={"newEmail"}
          rules={[
            { required: true, message: "Yangi Emailni kiritish majburiy!" },
            { type: "email", message: "Email xato kiritildi!" },
          ]}
        >
          <Input placeholder="Yangi Email:" />
        </Form.Item>

        {/* <Link to="/profile/change-email-code?email=fazliddinmirzaqosimov8@gmail.com"> */}
        <Button
          block
          loading={isLoading}
          style={{ marginTop: 20 }}
          type="primary"
          htmlType="submit"
        >
          Kirish
        </Button>
        {/* </Link> */}
      </Form>
    </FormContainer>
  );
}
