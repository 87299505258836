import React from "react";
import { Button, Checkbox, Form, Input, notification } from "antd";
import FormContainer from "../../../components/FormContainer";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import jwtAxios from "../../../auth/jwtAxios";

export default function ChangePassword() {
  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(
    (data) => jwtAxios.patch("/auth/update-password", data),
    {
      onSuccess() {
        notification.success({
          message: "Parolingiz muvoffaqiyatli o'zgardi!",
        });
        navigate("/profile/info");
      },
      onError(error: any) {
        notification.error({
          message: "Xatolik",
          description:
            error?.response?.data?.error || "Parolingiz o'zgartirilmadi!",
        });
      },
    }
  );

  return (
    <FormContainer title="Parolni o’zgaritrish!">
      <Form layout="vertical" onFinish={mutate}>
        <Form.Item
          label="Eski Parol"
          name={"oldPassword"}
          rules={[
            { required: true, message: "Eski Parolni kiritish majburiy!" },
          ]}
        >
          <Input.Password
            placeholder="Eski Parol:"
            className="password-input"
          />
        </Form.Item>{" "}
        <Form.Item
          label="Yangi Parol"
          name={"newPassword"}
          rules={[
            { required: true, message: "Yangi Parolni kiritish majburiy!" },
          ]}
        >
          <Input.Password
            placeholder="Yangi Parol:"
            className="password-input"
          />
        </Form.Item>{" "}
        <Form.Item
          label="Yangi Parolni Takrorlang"
          name={"repeatPassword"}
          rules={[
            { required: true, message: "Yangi Parolni takrorlash majburiy!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Siz kiritgan yangi parol mos emas!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Yangi Parolni Takrorlang:"
            className="password-input"
          />
        </Form.Item>
        <Button
          block
          style={{ marginTop: 20 }}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Yuborish
        </Button>
      </Form>
    </FormContainer>
  );
}
