import React, { useEffect } from "react";
import Navbar from "../../../layouts/components/Navbar";
import Card from "./Card";
import styles from "./index.module.scss";
import jwtAxios from "../../../auth/jwtAxios";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { SectionType } from "../../../shared/types/SectionType";
import { Spin } from "antd";
import { CourseType } from "../../../shared/types/CourseType";

export default function CourseSections() {
  const { id } = useParams();
  const { data, isLoading } = useQuery(["sections", id], () =>
    jwtAxios.get(`course-section?courseId=${id}&sort=order`)
  );
  const { data: courseData } = useQuery([`course/${id}`], () =>
    jwtAxios.get(`course/${id}`)
  );
  const sections = data?.data?.data;
  const course: CourseType = courseData?.data?.data?.course;

  
  return (
    <div>
      <Navbar course={course} type="course"/>
      {isLoading ? (
        <Spin
          style={{
            width: "100%",
            padding: "200px 0",
          }}
        ></Spin>
      ) : (
        <div className={styles.container}>
          <div className={styles.cards}>
            {sections.map((section: SectionType) => (
              <Card section={section} courseId={id}/>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
