import React, { useState } from "react";
import { PlanType } from "../../../shared/types";
import GradientBorder from "../../../components/GradientBorder";
import styles from "./index.module.scss";
import { FaCheck } from "react-icons/fa6";
import { Button, Modal, notification } from "antd";
import { colors } from "../../../shared/const/variables";
import { useAuthAction, useAuthData } from "../../../auth/JWTAuthProvider";
import jwtAxios from "../../../auth/jwtAxios";
import confirm from "antd/es/modal/confirm";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

type PropType = { plan: PlanType };

export default function Card({ plan }: PropType) {
  const color = colors[plan.order - 1];
  const [loading, setLoading] = useState(false);
  const { user } = useAuthData();
  const { refetchProfile } = useAuthAction();
  const navigate = useNavigate();
  const onBuyPlan = async () => {
    if (user?.activePlan) {
      return notification.error({
        message: "Sizda aktiv tarif mavjud!",
      });
    }
    if (!(user?.balance && user.balance >= plan.price)) {
      Modal.confirm({
        title: "Hisobingizda mablag'yetarli emas!",
        icon: <ExclamationCircleFilled color="red" />,
        content: "Hisobingizni to'ldirishni xohlaysizmi",
        className: styles.confirm,
        onOk() {
          navigate("/profile/purchase");
        },
        onCancel() {
          console.log("Yo'q");
        },
        okText: "Ha",
        cancelText: "Yo'q",
      });
      return;
    }
    setLoading(true);
    try {
      await jwtAxios.post(`user/buy-plan`, {
        planId: plan._id,
      });
      refetchProfile();
      notification.success({
        message: "Plan sotib olindi!",
      });
    } catch (error: any) {
      notification.error({
        message: "Xatolik",
        description: error?.response?.data?.error || "Keyinroq urunib ko'ring!",
      });
    }
    setLoading(false);
  };
  return (
    <div
      className={styles.card}
      style={{ "--color": color } as React.CSSProperties}
    >
      <h1>{plan.title}</h1>
      <div className={styles.advantages}>
        {plan.advantages.map((advantage: string) => (
          <p>
            <FaCheck />
            {advantage}
          </p>
        ))}
      </div>
      <p className={styles.description}>{plan.description}</p>

      <Button
        block
        loading={loading}
        type="primary"
        style={{ color: "var(--dark-primary-color)", background: color }}
        onClick={onBuyPlan}
      >
        Tarifni boshlash
      </Button>
    </div>
  );
}
