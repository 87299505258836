import { Button, Form, Input, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import GradientBorder from "../../../components/GradientBorder";
import Image from "./Image";
import ReactInputMask from "react-input-mask";
import { useState } from "react";
import { useAuthAction, useAuthData } from "../../../auth/JWTAuthProvider";
import jwtAxios from "../../../auth/jwtAxios";
import { useMutation } from "react-query";
import { useForm } from "antd/es/form/Form";
import { REACT_APP_API_URL } from "../../../shared/const/env";
import clsx from "clsx";

export default function Informations() {
  const { user } = useAuthData();
  const { refetchProfile } = useAuthAction();
  const [form] = useForm();

  const { isLoading, mutate } = useMutation(
    (data) => jwtAxios.patch("/user", data),
    {
      onError(error: any) {
        notification.error({
          message: "Xatolik",
          description:
            error?.response?.data?.error || "Profil o'zgartirilmadi!",
        });
      },
      onSuccess() {
        refetchProfile();
        notification.success({
          message: "Profil muvoffaqiyatli o'zgartirildi!",
        });
      },
    }
  );
  let expireDate = "";
  if (user?.activePlan?.planPurchase.createdAt) {
    const date = new Date(user?.activePlan?.planPurchase.createdAt);
    date.setDate(date.getDate() + user?.activePlan?.planPurchase.planDuration);

    expireDate = date.toISOString().split("T")[0];
  }
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <GradientBorder className={styles.form}>
          <Image src={user?.userData?.profileImage} />
          <Form
            form={form}
            initialValues={user?.userData}
            onFinish={(data) => {
              if (form.isFieldsTouched()) {
                return mutate(data);
              }
              notification.error({
                message: "O'zgartirish kiritganingiz yo'q!",
              });
            }}
            layout="vertical"
            className={styles["form-content"]}
          >
            <Form.Item
              name={"fullName"}
              label="To'liq ism"
              rules={[
                { required: true, message: "To'liq ismni kritish majburiy!" },
              ]}
            >
              <Input placeholder={"Fullname:"} />
            </Form.Item>
            <Form.Item
              label="Telefon"
              name={"phone"}
              rules={[
                { required: true, message: " " },
                {
                  validator: (_, phone) => {
                    if (!phone) {
                      return Promise.reject("Telefonni kiritish majburiy!");
                    }

                    if (phone.replace(/\D/g, "").length < 12) {
                      return Promise.reject(
                        "Telefon 9ta raqamdan iborat bo'lishi kerak!"
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <ReactInputMask
                className={"input"}
                required
                mask="+\9\98 99 999 99 99"
                maskChar=" "
                name="phone"
                id="phone"
                placeholder="Telefon raqamingizni kiriting:"
              />
            </Form.Item>
            <Form.Item
              // name={"fullName"}
              label="Email"
            >
              <Input
                disabled
                value={user?.userData.email}
                style={{ opacity: 0.6 }}
              />
            </Form.Item>
            <div className={styles.links}>
              <Link to="/profile/change-email">Emailni o'zgartirish</Link>{" "}
              <Link to="/profile/change-password">Parolni o'zgartirish</Link>
            </div>
            <Button
              block
              style={{ marginTop: 20 }}
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Saqlash
            </Button>
          </Form>
        </GradientBorder>
        {/* <GradientBorder className={styles.info}>
          <div className={styles["info-content"]}>
            <div className={styles["info-section"]}>
              <h1>Hamyon</h1>
              <p>
                Oxirgi to'lov miqdori: <i>{user?.lastPurchase?.amount || 0}$</i>
              </p>{" "}
              <p>
                Oxirgi to'lov vaqti:{" "}
                <i>{user?.lastPurchase ? user .lastPurchase.createdAt.split("T")[0] : "to'lanmagan"}</i>
              </p>
              <p className={styles.gold}>
                Balans: <i>{user?.balance}$</i>
              </p>
            </div>{" "}
            {user?.activePlan && (
              <div className={styles["info-section"]}>
                <h1>Plan</h1>
                <p>
                  Nomi: <i>{user.activePlan.planPurchase.planTitle}</i>
                </p>{" "}
                <p>
                  Narxi: <i>{Math.abs(user.activePlan.planPurchase.amount)}$</i>
                </p>
                <p>
                  Tugash muddati: <i>{expireDate}</i>
                </p>
              </div>
            )}
            <Link to={"/profile/purchase"}>
              <Button
                block
                style={{ marginTop: 20, }}
                type="primary"
              >
                Hisobni to’ldirish!
              </Button>
            </Link>
          </div>
        </GradientBorder> */}
      </div>
    </div>
  );
}
