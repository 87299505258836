import React, { useState } from "react";
import styles from "./index.module.scss";
import { FaCamera } from "react-icons/fa";
import jwtAxios from "../../../auth/jwtAxios";
import { Spin, notification } from "antd";
import { useAuthAction } from "../../../auth/JWTAuthProvider";
import { imageExtensions } from "../../../shared/const/variables";
import profile from "../../../assets/profile.png";
import { REACT_APP_API_URL } from "../../../shared/const/env";

type PropType = {
  src?: string;
};

export default function Image({ src }: PropType) {
  const { refetchProfile } = useAuthAction();
  const [loading, setLoading] = useState(false);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (!e.target.files?.[0]) {
        return;
      }
      formData.append("image", e.target.files?.[0]);

      await jwtAxios.post("/user/upload-photo", formData, {
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });

      refetchProfile();

      notification.success({ message: "Rasm muvoffaqiyatli o'zgartirildi!" });
    } catch (error: any) {
      notification.error({
        message: "Xatolik",
        description: error?.response?.data?.error || "Rasm o'zgartirilmadi!",
      });
    }
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <label htmlFor="image" className={styles.image}>
        <img
          src={src ? `${REACT_APP_API_URL}${src}` : profile}
          alt="profile image"
          onError={(e) => {
            e.currentTarget.src = profile;
            e.currentTarget.onerror = null; // prevents looping
          }}
        />
        <input
          type="file"
          id="image"
          onChange={onChange}
          accept={imageExtensions.map((ext) => `image/${ext}`).join(", ")}
        />
        <div>
          <FaCamera size={20} />
        </div>
      </label>
    </Spin>
  );
}
