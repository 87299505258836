import styles from "./index.module.scss";
import Card from "./Card";
import ProfileTemplate from "../../../components/ProfileTemplate";
import { useQuery } from "react-query";
import jwtAxios from "../../../auth/jwtAxios";
import { Spin } from "antd";
import { CourseType } from "../../../shared/types/CourseType";

export default function Courses() {
  const { data, isLoading, isError } = useQuery(["courses"], () =>
    jwtAxios.get("/course?sort=priority")
  );
  const courses = data?.data?.data || [];

  return (
    <div className={styles.content}>
      <ProfileTemplate title="Kurslar" className={styles.container}>
        {isLoading ? (
          <Spin spinning className={styles.spin}></Spin>
        ) : isError ? (
          "Error"
        ) : (
          <div className={styles.cards}>
            {courses.map((course:CourseType) => (
              <Card course={course}/>
            ))}
          </div>
        )}
      </ProfileTemplate>
    </div>
  );
}
