import React, { CSSProperties } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";

type PropType = {
  children: React.ReactNode;
  className?: string;
  color1?: string;
  color2?: string;
  style?: React.CSSProperties;
};

export default function GradientBorder({
  children,
  className,
  color1 = "var(--gold-color)",
  color2 = "var(--gold-color-0)",
  style,
  ...rest
}: PropType) {
  return (
    <div
      className={clsx(styles["border-container"], className)}
      style={
        { "--color1": color1, "--color2": color2, ...style } as CSSProperties
      }
      {...rest}
    >
      {children}
    </div>
  );
}
