import React from "react";
import { Button, Checkbox, Form, Input, notification } from "antd";
import FormContainer from "../../../components/FormContainer";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import jwtAxios from "../../../auth/jwtAxios";
import { useAuthAction } from "../../../auth/JWTAuthProvider";

export default function ChangeEmailCode() {
  const [serchParams] = useSearchParams();
  const email = serchParams.get("email");
  const navigate = useNavigate();
  const { refetchProfile } = useAuthAction();

  const { isLoading, mutate } = useMutation(
    (data) => jwtAxios.post("/auth/update-email", data),
    {
      onSuccess() {
        notification.success({
          message: "Emailingiz muvoffaqiyatli o'zgardi!",
        });
        navigate("/profile/info");
        refetchProfile();
      },
      onError(error: any) {
        notification.error({
          message: "Xatolik",
          description:
            error?.response?.data?.error || "Emailingiz o'zgartirilmadi!",
        });
      },
    }
  );

  if (!email) {
    return <Navigate to="/auth/forgot-password" />;
  }

  return (
    <FormContainer
      title="Kodni kiriting!"
      description={`Yangi Emailingizga ( ${email?.split("@")[0][0]}***${
        email?.split("@")[0][email.split("@")[0].length - 1]
      }@${
        email?.split("@")[1]
      } ) kod jo’natdik. Emailni o’zgartirish uchun kodni kiriting!`}
    >
      <Form layout="vertical" onFinish={mutate}>
        <Form.Item label="Kod" name={"code"}>
          <Input placeholder="Kod:" type="number" />
        </Form.Item>
        <Button
          block
          style={{ marginTop: 20 }}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Yuborish
        </Button>
      </Form>
    </FormContainer>
  );
}
