import { RouteType } from "../shared/types/RoutType";
import { authRoutes } from "./auth";
import { courseRoutes } from "./course";
import { errorRoutes } from "./error";
import { profileRoutes } from "./profile";
import { Navigate } from "react-router-dom";

export const unAuthorizedPaths: RouteType[] = [
  { path: "/", element: <Navigate to="/auth/signin" /> },
  ...authRoutes,
  ...errorRoutes,
];

export const inactivePaths: RouteType[] = [
  { path: "/", element: <Navigate to="/courses" /> },
  ...courseRoutes,
  ...profileRoutes,
  ...errorRoutes,
];

export const activePaths: RouteType[] = [
  { path: "/", element: <Navigate to="/courses" /> },
  ...courseRoutes,
  ...profileRoutes,
  ...errorRoutes,
];
