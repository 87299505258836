import React from "react";
import styles from "./index.module.scss";
import Card from "./Card";
import { useQuery } from "react-query";
import jwtAxios from "../../../auth/jwtAxios";
import { PlanType } from "../../../shared/types";
import ProfileTemplate from "../../../components/ProfileTemplate";
import { Spin } from "antd";

export default function Plans() {
  const { data, isLoading } = useQuery("plans",  () => jwtAxios.get("/plan"),
   );
  const plans = data?.data.data;
  return (
    <ProfileTemplate title="Tariflar">
      {isLoading ? (
        <Spin className={styles.spin}></Spin>
      ) : (
        <div className={styles.cards}>
          {plans?.map((plan: PlanType) => (
            <Card plan={plan} />
          ))}
        </div>
      )}
    </ProfileTemplate>
  );
}
