import React from "react";
import styles from "./index.module.scss";
import image from "../../../assets/lock.svg";
import { VideoType } from "../../../shared/types/VideoType";

type PropType = {
  video: VideoType;
};

export default function VideoCard({ video }: PropType) {
  return (
    <div className={styles.video}>
      <img src={image} alt="lock" className={styles.lock} />

      <h2>{video.title}</h2>
    </div>
  );
}
