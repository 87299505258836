import React from "react";
import styles from "./index.module.scss";
import noimage from "../../../assets/noimage.png";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { SectionType } from "../../../shared/types/SectionType";
import { REACT_APP_API_URL } from "../../../shared/const/env";

type PropType = {
  section: SectionType;
  courseId?: string;
};
export default function Card({ section, courseId }: PropType) {
  return (
    <div className={styles.card}>
      <img
        src={
          section?.image?.location
            ? `${REACT_APP_API_URL}${section.image.location}`
            : noimage
        }
        alt=""
        onError={(e) => {
          e.currentTarget.src = noimage;
          e.currentTarget.onerror = null; // prevents looping
        }}
      />
      <div className={styles.text}>
        <h1>{section.title}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: section.description || "",
          }}
        ></p>
        <Link
          to={`/platform/section/${section._id}/course/${courseId}/video?sectionTitle=${section.title}`}
          className={styles.button}
        >
          <Button block type="primary" htmlType="submit">
            Kirish
          </Button>
        </Link>
      </div>
    </div>
  );
}
