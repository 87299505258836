import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification } from "antd";
import FormContainer from "../../../components/FormContainer";
import { Link, useSearchParams, useNavigate, Navigate } from "react-router-dom";
import { useAuthAction } from "../../../auth/JWTAuthProvider";
import jwtAxios from "../../../auth/jwtAxios";

export default function ForgotPasswordCode() {
  const [serchParams] = useSearchParams();
  const email = serchParams.get("email");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  if (!email) {
    return <Navigate to="/auth/forgot-password" />;
  }

  const updatePasswordWithCode = async ({
    email,
    code,
  }: {
    email: string;
    code: string;
  }) => {
    setLoading(true);
    try {
      const res = await jwtAxios.post("/auth/update-password-with-code", {
        email,
        code,
      });

      notification.success({ message: "Parol muvoffaqiyatli o'zgartirildi!" });

      navigate(`/auth/signin`);
    } catch (error: any) {
      notification.error({
        message: "Xatolik",
        description: error?.response?.data?.error || "Xatolik yuz berdi!",
      });
    }
    setLoading(false);
  };

  return (
    <FormContainer
      title="Kodni kiriting!"
      description="Kiritlgan emailingizga kod jo’natdik.Parolni o’zgartirish uchun kodni kiriting!"
    >
      <Form
        layout="vertical"
        onFinish={updatePasswordWithCode}
        initialValues={{ email }}
      >
        <p style={{ display: "none" }}>
          <Form.Item label="Email" name={"email"}>
            <Input placeholder="Email:" disabled />
          </Form.Item>{" "}
        </p>
        <Form.Item label="Kod" name={"code"}>
          <Input placeholder="Kod:" type="number" />
        </Form.Item>
        <p>
          <Link to="/auth/forgot-password">Parolni o'zgartirish!</Link>
        </p>
        <Button
          block
          style={{ marginTop: 20 }}
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Yuborish
        </Button>
      </Form>
    </FormContainer>
  );
}
