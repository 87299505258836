import Content from "../components/Content";
import styles from "./index.module.scss";

export default function AuthLayout() {
  return (
    <div className={styles["auth-layout"]}> 
      <main className={styles["content"]}>
        <Content />
      </main>{" "}
    </div>
  );
}
