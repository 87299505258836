import styles from "./index.module.scss";
import { SectionType } from "../../../shared/types/SectionType";
import { useState } from "react";
import { useQuery } from "react-query";
import jwtAxios from "../../../auth/jwtAxios";
import { Button, Spin } from "antd";
import { VideoType } from "../../../shared/types/VideoType";
import VideoCard from "./VideoCard";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

type PropType = {
  section: SectionType;
};

export default function SectionCard({ section }: PropType) {
  const [open, setOpen] = useState<boolean>(section.order == 1);
  const { data, isLoading } = useQuery(
    ["videos", "sectionId", section],
    () =>
      jwtAxios.get(
        `/video?sectionId=${section._id}&sort=order&fields=title,order`
      ),
    {
      enabled: open,
    }
  );
  const videos = data?.data?.data;

 
  return (
    <div className={styles.section}>
      <h1 className={styles.title} onClick={() => setOpen(!open)}>
        {section.order}. {section.title}
        {open ? <FaChevronDown /> : <FaChevronRight />}
      </h1>
      <Spin style={{ padding: 30 }} spinning={isLoading}></Spin>

      {open && (
        <>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: section.description ||"",
            }}
          ></p>

          {videos?.map((video: VideoType) => (
            <VideoCard video={video} />
          ))}
        </>
      )}
    </div>
  );
}
