import React, { useState } from "react";
import styles from "./index.module.scss";
import { Button, Form, Input } from "antd";
import FormContainer from "../../../components/FormContainer";
import { Link } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { useForm } from "antd/es/form/Form";
import { useAuthAction } from "../../../auth/JWTAuthProvider";

export default function Signup() {
  const [phone, setPhone] = useState("");
  const [form] = useForm();
  const { signUpUser } = useAuthAction();
  return (
    <FormContainer
      title="Registratsiya"
      // description="Emailingizga ( f****8@gmail.com ) linkni jo’natdik. Uni bosib akkauntingizga kirishnigz mumkin!"
    >
      <Form layout="vertical" onFinish={signUpUser} form={form}>
        <Form.Item
          label="Email"
          name={"email"}
          rules={[
            { required: true, message: "Emailni kiritish majburiy!" },
            { type: "email", message: "Email xato kiritildi!" },
          ]}
        >
          <Input placeholder="Email:" />
        </Form.Item>
        <Form.Item
          label="Telefon"
          name={"phone"}
          rules={[
            { required: true, message: " " },
            {
              validator: () => {
                if (!phone) {
                  return Promise.reject("Telefonni kiritish majburiy!");
                }

                if (phone.replace(/\D/g, "").length < 12) {
                  return Promise.reject(
                    "Telefon 9ta raqamdan iborat bo'lishi kerak!"
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <ReactInputMask
            className="input"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            value={phone}
            required
            mask="+\9\98 99 999 99 99"
            maskChar=" "
            name="phone"
            id="phone"
            placeholder="Telefon raqamingizni kiriting:"
          />
        </Form.Item>
        <Form.Item
          label="Parol"
          name={"password"}
          rules={[{ required: true, message: "Parolni kiritish majburiy!" }]}
        >
          <Input.Password className="password-input" placeholder="Parol:" />
        </Form.Item>{" "}
        <Form.Item
          label="Parolni Takrorlang"
          name={"newPassword"}
          rules={[
            { required: true, message: "Parolni takrorlash majburiy!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Siz kiritgan yangi parol mos emas!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            className="password-input"
            placeholder="Parolni Takrorlang:"
          />
        </Form.Item>
        <div className={styles.links}>
          <p>
            Akkaunt Bormi? <Link to={"/auth/signin"}>Login</Link>
          </p>
        </div>
        <Button
          block
          style={{ marginTop: 20 }}
          type="primary"
          htmlType="submit"
        >
          Kirish
        </Button>
      </Form>
    </FormContainer>
  );
}
