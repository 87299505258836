import { RouteType } from "../../shared/types/RoutType";
import ChangeEmail from "./ChangeEmail";
import ChangeEmailCode from "./ChangeEmailCode";
import ChangePassword from "./ChangePassword";
import Informations from "./Informations";
import Plans from "./Plans";
import Purchase from "./Purchase";
import Wallet from "./Wallet";

export const profileRoutes: RouteType[] = [
  {
    path: "/profile/change-email",
    element: <ChangeEmail />,
  },
  {
    path: "/profile/change-email-code",
    element: <ChangeEmailCode />,
  },
  {
    path: "/profile/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/profile/info",
    element: <Informations />,
  },
  {
    path: "/profile/plans",
    element: <Plans />,
  },
  {
    path: "/profile/purchase",
    element: <Purchase />,
  },
  {
    path: "/profile/wallet",
    element: <Wallet />,
  },
];
