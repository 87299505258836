import React from "react";
import { Button, Form, Input } from "antd";
import FormContainer from "../../../components/FormContainer";
import { Link, Navigate, useLocation, useSearchParams } from "react-router-dom";

export default function EmailSent() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  if (!email) {
    return <Navigate to="/"/>;
  }
  return (
    <FormContainer
      title="Emailingizni tekshiring!"
      description={`Emailingizga ( ${email?.split("@")[0][0]}***${
        email?.split("@")[0][email.split("@")[0].length - 1]
      }@${
        email?.split("@")[1]
      } ) linkni jo’natdik. Uni bosib akkauntingizga kirishnigz mumkin!`}
    >
      {/* <Link to="https://mail.google.com/mail/u/0/#inbox">
        <Button block style={{ marginTop: 20 }} type="primary">
          Emailga kirish
        </Button>
      </Link> */}
    </FormContainer>
  );
}
