import { Routes, Route, Navigate } from "react-router-dom";
import { RouteType } from "../shared/types/RoutType";
import { useAuthData } from "../auth/JWTAuthProvider";
import { activePaths, inactivePaths, unAuthorizedPaths } from "../pages";

function useRouteGenerator() {
  const { isAuthenticated, user } = useAuthData();

  const routes = !isAuthenticated
    ? unAuthorizedPaths
    // : !user.activePlan
    // ? inactivePaths
    : activePaths;

  return ( 
    <Routes>
      {routes.map((route: RouteType, index: number) => (
        <Route {...route} key={index}></Route>
      ))}
     </Routes>
  );
}

export default useRouteGenerator;
