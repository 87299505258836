import React from "react";
import styles from "./index.module.scss";
import noimage from "../../../assets/noimage.png";
import image1 from "../../../assets/test.png";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { CourseType } from "../../../shared/types/CourseType";
import { REACT_APP_API_URL } from "../../../shared/const/env";
import { useAuthData } from "../../../auth/JWTAuthProvider";

type PropType = {
  course: CourseType;
};

export default function Card({ course }: PropType) {
  const { user } = useAuthData();
  const isCourseStarted = user?.userData.startedCourses.find(
    (startedCourse) => startedCourse._id == course._id
  );
  return (
    <div className={styles.card}>
      <div className={styles.top}>
        {" "}
        <img
          src={`${REACT_APP_API_URL}${course.image?.location}` || noimage}
          alt=""
          onError={(e) => {
            e.currentTarget.src = noimage ;
            e.currentTarget.onerror = null; // prevents looping
          }}
        />
        <div className={styles.text}>
          <h1>{course.title}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: course.description,
            }}
          ></p>
        </div>{" "}
      </div>
      <Link
        to={
          isCourseStarted
            ? `/platform/course/${course._id}`
            : `/course/${course._id}`
        }
        className={styles.button}
      >
        <Button block type="primary" htmlType="submit">
          {isCourseStarted ?  "Davom ettirish!": "Kirish"}
        </Button>
      </Link>
    </div>
  );
}
