import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navbar from "../../../layouts/components/Navbar";
import VideoList from "./VideoList";
import Video from "./Video";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import jwtAxios from "../../../auth/jwtAxios";
import { Spin } from "antd";
import { VideoType } from "../../../shared/types/VideoType";

export default function SectionVideos() {
  const { sectionId, videoId, courseId } = useParams();
  const [nextVideoId, setNextVideoId] = useState("");
  const { data: videosData } = useQuery(
    ["videos", "sectionId", sectionId],
    () => jwtAxios.get(`video?sectionId=${sectionId}&sort=order`)
  );
  const { data: videoData, isLoading } = useQuery(
    ["video", videoId],
    () => jwtAxios.get(`video/${videoId}`),
    {
      enabled: !!videoId,
    }
  );

  const { data: sectionData } = useQuery(["sectionData", sectionId], () =>
    jwtAxios.get(`/course-section/${sectionId}?fields=title`)
  );
  const { data: progressData, refetch } = useQuery(
    ["progress", "sectionId", sectionId],
    () => jwtAxios.get(`progress/${sectionId}`)
  );

  const progress = progressData?.data?.data?.progress;
  const section = sectionData?.data?.data?.section;
  const videos = videosData?.data?.data;
  const video = videoData?.data?.data?.video;

  useEffect(() => {
    if (progress && videos?.[0]) {
      const video = videos.find(
        (video: VideoType) => video.order === progress + 1
      );
      setNextVideoId(video?._id);
    }
  }, [progress, videos]);

  return (
    <>
      <Navbar
        type="section"
        sectionTitle={section?.title || ""}
        backUrl={`/platform/course/${courseId}`}
      />
      <div className={styles["content-wrapper"]}>
        <div className={styles.content}>
          <VideoList videos={videos} progress={progress} />
          {isLoading ? (
            <Spin
              style={{
                width: "100%",
                padding: "20vh 0",
              }}
            ></Spin>
          ) : (
            <Video
              video={video}
              nextVideoId={nextVideoId}
              refetchProgress={refetch}
              isLastVideo={
                video?.order === videos?.length || video?.order < progress
              }
            />
          )}
        </div>
      </div>
    </>
  );
}
