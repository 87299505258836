import React from "react";
import styles from "./index.module.scss";
import { Button, Checkbox, Form, Input } from "antd";
import FormContainer from "../../../components/FormContainer";
import { Link } from "react-router-dom";
import { useAuthAction } from "../../../auth/JWTAuthProvider";

export default function Signin() {
  const { signInUser } = useAuthAction();

  return (
    <FormContainer
      title="Login"
    >
      <Form
        layout="vertical"
        onFinish={signInUser}
        initialValues={{ rememberMe: true }}
      >
        <Form.Item
          label="Email"
          name={"email"}
          rules={[
            { required: true, message: "Emailni kiritish majburiy!" },
            { type: "email", message: "Email xato kiritildi!" },
          ]}
        >
          <Input placeholder="Email:" />
        </Form.Item>
        <Form.Item
          label="Parol"
          name={"password"}
          rules={[{ required: true, message: "Parolni kiritish majburiy!" }]}
        >
          <Input.Password className="password-input" placeholder="Parol:" />
        </Form.Item>
        <Form.Item
          name="rememberMe"
          valuePropName="checked"
          // wrapperCol={{ offset: 8, span: 16 }}
        >
          <Checkbox>Mani eslab qoling</Checkbox>
        </Form.Item>

        <div className={styles.links}>
          <Link to={"/auth/signup"}>Akkaunt yo'qmi?</Link>

          <Link to={"/auth/forgot-password"}>Parolni unutdizmi?</Link>
        </div>
        <Button
          block
          style={{ marginTop: 20 }}
          type="primary"
          htmlType="submit"
        >
          Kirish
        </Button>
      </Form>
    </FormContainer>
  );
}
