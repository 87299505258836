import { RouteType } from "../../shared/types/RoutType";
import Courses from "./Courses";
import NewCourse from "./NewCourse";
import CourseSections from "./CourseSections";
import SectionVideos from "./SectionVideos";

export const courseRoutes: RouteType[] = [
  {
    path: "/courses",
    element: <Courses />,
  },
  {
    path: "/course/:id",
    element: <NewCourse />,
  },
  {
    path: "/platform/course/:id",
    element: <CourseSections />,
  },
  {
    path: "/platform/section/:sectionId/course/:courseId/video",
    element: <SectionVideos />,
  },
  {
    path: "/platform/section/:sectionId/course/:courseId/video/:videoId",
    element: <SectionVideos />,
  },
];
