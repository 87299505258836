import React from "react";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import styles from "./index.module.scss";

export default function ProfileLayout() {
  return (
    <div className={styles.layout}>
      <Navbar />
      <main className={styles.content}>
        <Content />
      </main>
    </div>
  );
}
