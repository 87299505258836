import React from 'react'
import Content from '../components/Content'
import Sidebar from '../components/Sidebar'
import styles from "./index.module.scss"

type PropType = {
  disableSidebar?:boolean
}

export default function MainLayout({disableSidebar}:PropType) {
  return (
    <div className={styles.layout}>  
      {disableSidebar || <Sidebar/>}
      <main>
      <Content/>
      </main>
    </div>
  )
}
