import React from "react";
import GradientBorder from "../GradientBorder";
import styles from "./index.module.scss";

type PropType = {
  title?: string;
  description?: string;
  children?: React.ReactNode;
};

export default function FormContainer({
  title,
  description,
  children,
}: PropType) {
  return (
    <div className={styles.container}>
      <GradientBorder>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.description}>{description}</p>
          {children}{" "}
        </div>
      </GradientBorder>
    </div>
  );
}
