import React, { CSSProperties } from "react";
import styles from "./index.module.scss";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import lock from "../../../assets/lock.svg";
import { VideoType } from "../../../shared/types/VideoType";

export default function VideoList({
  videos,
  progress,
}: {
  videos: VideoType[];
  progress: number;
}) {
  const { sectionId, videoId, courseId } = useParams();
  console.log({ progress });

  const percent =
    videos?.length === progress
      ? 100
      : videos?.length === 0 || progress === 1
      ? 0
      : Math.floor(((progress - 1) * 100) / videos?.length);
 
  return (
    <div
      className={styles["sidebar"]}
      style={
        {
          "--progress": `clamp(10px,${percent}%,100%)`,
        } as CSSProperties
      }
    >
      <div className={styles["progress"]}>
        <p> {videos?.length || 0}ta darslik</p>
        <div className={styles["progress-stick"]}></div>
        <h3>{percent}%</h3>
      </div>
      <div className={styles["videos"]}>
        {videos?.map((video) => (
          <Link
            to={
              video.order <= progress
                ? `/platform/section/${sectionId}/course/${courseId}/video/${
                    video._id || ""
                  }`
                : ""
            }
            className={clsx(
              styles["video"],
              videoId === video._id && styles["active"]
            )}
          >
            <div className={styles["number"]}>
              {video.order <= progress ? video.order : <img src={lock} />}
            </div>
            <div className={styles["text"]}>
              <h1>{video.title}</h1>
              <p>
                {video.duration
                  ? `${Math.floor(video.duration / 60)} minut ${
                      video.duration % 60
                    } sekund`
                  : "0 sekund"}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
