import React from "react";
import { useLocation } from "react-router-dom";
import { useAuthData } from "../auth/JWTAuthProvider";
import AuthLayout from "./AuthLayout";
import ProfileLayout from "./ProfileLayout";
import useRouteGenerator from "../utils/routeGenerator";
import { activePaths, inactivePaths, unAuthorizedPaths } from "../pages";
import MainLayout from "./MainLayout";
import Content from "./components/Content";
import logo from "../assets/logo.png";
import { Spin } from "antd";

export default function AppLayout() {
  let location = useLocation();
  const { isLoading } = useAuthData();

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "5vh",
        }}
      >
        <img
          src={logo}
          style={{
            width: 100,
          }}
          alt=""
        />

        <Spin spinning></Spin>
      </div>
    );
  }
  if (location.pathname.startsWith("/auth")) {
    return <AuthLayout />;
  }
  if (location.pathname.startsWith("/profile")) {
    return <ProfileLayout />;
  }

  if (
    location.pathname.startsWith("/platform") ||
    location.pathname.startsWith("/course")
  ) {
    return (
      <MainLayout
        disableSidebar={
          location.pathname.startsWith("/platform/section") ||
          location.pathname.startsWith("/course/")
        }
      />
    );
  }

  return <Content />;
}
