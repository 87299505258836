import ProfileTemplate from "../../../components/ProfileTemplate";
import GradientBorder from "../../../components/GradientBorder";
import styles from "./index.module.scss";
import Dragger from "antd/es/upload/Dragger";
import { Button, UploadProps, notification } from "antd";
import { RiDragDropLine } from "react-icons/ri";
import clsx from "clsx";
import { useState } from "react";
import { RcFile } from "antd/es/upload";
import { useNavigate } from "react-router-dom";
import jwtAxios from "../../../auth/jwtAxios";

export default function Purchase() {
  const [file, setFile] = useState<RcFile | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const props: UploadProps = {
    fileList: file ? [file] : [],
    className: styles.dragger,
    name: "file",
    multiple: false,
    // showUploadList: false,
    onRemove: () => {
      setFile(null);
    },
    beforeUpload(file) {
      setFile(file || null);
    },
  };

  const uploadFile = async () => {
    if (!file) {
      return notification.error({
        message: "Chekni yuklang!",
      });
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      await jwtAxios.post("purchase-request/send", formData, {
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });

      notification.success({
        message: "To'lov muvaffaqiyatli yuborildi!",
        description: "So'rovingiz uzog'i bir soat ichida ko'rib chiqiliadi!",
      });
      navigate("/profile/info");
    } catch (error: any) {
      notification.error({
        message: "Xatolik",
        description:
          error?.response?.data?.error ||
          "Iltimos fayl hajmini kamaytiring yoki keyinroq urunib ko'ring!",
      });
    }
    setLoading(false);
  };

  return (
    <ProfileTemplate title="Hisobni to'ldirish">
      <GradientBorder className={styles.container}>
        <h1
          className={styles.title}
          onClick={() =>
            navigator.clipboard.writeText(window.location.href).then(() =>
              notification.success({
                message: "Karta raqami kopirovat bo'ldi",
              })
            )
          }
        >
          8600 1404 3863 2334
        </h1>
        <p className={styles.description}>
          Ushbu karta raqamiga pul tashlaganingizdan so’ng chekni rasmini shu
          yerga tashlang va bir soatda hisobingizga pul tushadi!
        </p>
        <Dragger {...props} style={{ color: "white" }}>
          <p className={clsx(styles.gold, "ant-upload-drag-icon")}>
            <RiDragDropLine size={100} />
          </p>
          <p className={clsx(styles.white, "ant-upload-text")}>
            Bosing yoki To'lov chekini shu yerga tashlang!
          </p>
          <p className={clsx(styles.white, "ant-upload-hint")}>
            24 soatda bir marta so'rov jo'natsangiz bo'ladi!
          </p>
        </Dragger>

        <Button
          type="primary"
          onClick={uploadFile}
          loading={loading}
          className={styles.button}
        >
          Yuborish
        </Button>
      </GradientBorder>
    </ProfileTemplate>
  );
}
