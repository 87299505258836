import React from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import styles from "./index.module.scss";
import { Button, Spin } from "antd";
import SectionCard from "./SectionCard";
import { useQuery } from "react-query";
import jwtAxios from "../../../auth/jwtAxios";
import { SectionType } from "../../../shared/types/SectionType";
import Course from "./Course";

export default function NewCourse() {
  const { id } = useParams();

  const { data } = useQuery(["sections", "courseId", id], () =>
    jwtAxios.get(`/course-section?courseId=${id}&sort=priority`)
  );
  const {
    data: courseData,
    isLoading,
    isError,
  } = useQuery(["course", id], () => jwtAxios.get(`/course/${id}`));

  const sections = data?.data?.data;
  const course = courseData?.data?.data?.course;
  if (!isLoading && isError ) {
    return <Navigate to="/courses" />;
  }
  return (
    <div className={styles.content}>
      <div className={styles["main-title"]}>
        <h1>Frontend kursi</h1>
        <Link to="/courses">
          {" "}
          <Button ghost>Orqaga</Button>
        </Link>
      </div>
      {isLoading ? (
        <Spin
          style={{
            width: "100%",
            padding: "200px 0",
          }}
        ></Spin>
      ) : (
        <div className={styles.container}>
          <Course course={course} />

          <div className={styles.sections}>
            {sections?.map((section: SectionType, index: number) => (
              <SectionCard section={{ ...section, order: index + 1 }} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
