import React from "react";
import styles from "./index.module.scss";

type PropType = {
  children: React.ReactNode;
  title: string;
  className?: string;
};

export default function ProfileTemplate({
  children,
  title,
  className,
  ...rest
}: PropType) {
  return (
    <div className={styles["container"]} {...rest}>
      <h1 className={styles["title"]}>{title}</h1>
      {children}
    </div>
  );
}
