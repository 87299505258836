import React, { useState } from "react";
import styles from "./index.module.scss";
import { Button, Form, Input, notification } from "antd";
import FormContainer from "../../../components/FormContainer";
import { Link, useNavigate } from "react-router-dom";
import { useAuthAction } from "../../../auth/JWTAuthProvider";
import jwtAxios from "../../../auth/jwtAxios";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const sendNewPasswordCode = async ({
    email,
    newPassword,
  }: {
    email: string;
    newPassword: string;
  }) => {
    setLoading(true);
    try {
      await jwtAxios.post("/auth/send-new-password-code", {
        email,
        newPassword,
      });

      navigate(`/auth/forgot-password-code?email=${email}`);
    } catch (error:any) {
      notification.error({ message: "Xatolik",
      description: error?.response?.data?.error || "Xatolik yuz berdi!" });
    }
    setLoading(false);
  };
  return (
    <FormContainer
      title="Parolni Yangilash"
      // description="Emailingizga ( f****8@gmail.com ) linkni jo’natdik. Uni bosib akkauntingizga kirishnigz mumkin!"
    >
      <Form layout="vertical" onFinish={sendNewPasswordCode}>
        <Form.Item
          label="Email"
          name={"email"}
          rules={[
            { required: true, message: "Emailni kiritish majburiy!" },
            { type: "email", message: "Email xato kiritildi!" },
          ]}
        >
          <Input placeholder="Email:" />
        </Form.Item>
        <Form.Item
          label="Yengi Parol"
          name={"newPassword"}
          rules={[
            { required: true, message: "Yengi Parolni kiritish majburiy!" },
          ]}
        >
          <Input.Password
            className="password-input"
            placeholder="Yengi Parol:"
          />
        </Form.Item>
        <div className={styles.links}>
          <Link to={"/auth/signin"}>Login</Link>
        </div>
        <Button
          block
          style={{ marginTop: 20 }}
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Yuborish
        </Button>
      </Form>
    </FormContainer>
  );
}
// title="Kodni kiriting!"
// description="Yangi Emailingizga ( n****z@gmail.com ) kod jo’natdik.Parolni o’zgartirish uchun kodni kiriting!"
