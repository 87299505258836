import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { notification } from "antd";
import jwtAxios, { setAuthToken } from "./jwtAxios";
import { UserType } from "../shared/types/UserType";

export type UserDataType =
  | {
      user: UserType;
      isAuthenticated: true;
      isLoading: boolean;
    }
  | {
      user: null;
      isAuthenticated: false;
      isLoading: boolean;
    };

export type UserActionsType = {
  signUpUser?: (x: {
    name: string;
    email: string;
    password: string;
  }) => Promise<void>;
  signInWithAuth?: (code: string, state: string) => Promise<void>;
  signInUser?: (x: { email: string; password: string }) => Promise<void>;
  logout?: () => Promise<void>;
};

const JWTAuthContext = createContext<UserDataType>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});

const JWTAuthActionsContext = createContext<any>({});

export const useAuthData = () => useContext(JWTAuthContext);
export const useAuthAction = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState<UserDataType>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem("token") || searchParams.get("jwt");

      if (!token) {
        setUserData({
          user: null,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .get("/user/profile")
        .then((res) => {
          setUserData({
            user: res.data.data,
            isLoading: false,
            isAuthenticated: true,
          });
        })
        .catch(() =>
          setUserData({
            user: null,
            isLoading: false,
            isAuthenticated: false,
          })
        );
    };

    getAuthUser();
  }, []);

  const signInUser = async ({
    email,
    password,
    rememberMe,
  }: {
    email: string;
    password: string;
    rememberMe: boolean;
  }) => {
    try {
      setUserData((prev) => ({ ...prev, isLoading: true }));

      const { data } = await jwtAxios.post("/auth/login", {
        email,
        password,
      });

      setAuthToken(data.data.token);

      const res = await jwtAxios.get("/user/profile");

      rememberMe || localStorage.removeItem("token");

      setUserData({
        user: res.data.data,
        isAuthenticated: true,
        isLoading: false,
      });
      navigate("/courses");
    } catch (error: any) {
 
      notification.error({
        message: "Xatolik",
        description: error?.response?.data?.error || "Email yoki parol xato!",
      });
      setUserData({
        user: null,
        isAuthenticated: false,
        isLoading: false,
      });
    }
  };

  const signUpUser = async ({
    email,
    password,
    phone,
  }: {
    email: string;
    password: string;
    phone: string;
  }) => {
    setUserData({
      ...userData,
      isLoading: true,
    });
    try {
      await jwtAxios.post("/auth/register", {
        email,
        password,
        phone,
      });
      setUserData({
        ...userData,
        isLoading: false,
      });
      navigate(`/auth/email-sent?email=${email}`);
    } catch (error: any) {
      api.error({
        message: "Registratsiyada xatolik yuz berdi!",
      });

      setUserData({
        user: null,
        isAuthenticated: false,
        isLoading: false,
      });
    }
  };

  const logout = async () => {
    setAuthToken();
    setUserData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    navigate("/auth/signin");
  };

  const refetchProfile = async () => {
    try {
      const res = await jwtAxios.get("/user/profile", {});
      setUserData({
        user: res.data.data,
        isAuthenticated: true,
        isLoading: false,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {contextHolder}
      <JWTAuthContext.Provider
        value={{
          ...userData,
        }}
      >
        <JWTAuthActionsContext.Provider
          value={{
            signUpUser,
            signInUser,
            logout,
            setUserData,
            refetchProfile,
          }}
        >
          {children}
        </JWTAuthActionsContext.Provider>
      </JWTAuthContext.Provider>
    </>
  );
};
export default JWTAuthAuthProvider;
