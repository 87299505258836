//image extensions

export const imageExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "tiff",
    "tif",
    "raw",
    "svg",
    "psd",
    "ai",
    "eps",
  ];
  

 export const colors = ["#E59F1C", "#C0C0C0", "var(--gold-color)"];
